.ProductTableDiv {
    position: relative;
}

.AddUserDiv {
    display: flex;
    position: absolute;
    right: 30px;
    top: -60px;
    background-color: rgb(55, 55, 55);
    width: 45px;
    height: 45px;
    border-radius: 8px;
    justify-content: center;
    align-items: left;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.AddUserDiv:hover {
    background-color: #4e4e4e;
}

.AddUserDiv i {
    font-size: 20px;
    color: #ffffff;
    transform: translate(2px, 2px);
}

.ProductTableDiv table {
    margin-top: 30px;
    font-family: 'Oswald', sans-serif;
    border-collapse: collapse;
}

.ProductTableDiv th {
    color: #000000;
    width: 25vw;
    height: 35px;
    text-align: left;
    padding-left: 10px;
}

.ProductTableDiv td {
    background-color: #ffffff;
    width: 25vw;
    height: 50px;
    text-align: left;
    padding-left: 10px;
}

.ProductTableDiv tr {
    border-bottom: 1px solid #dddddd;
}

.ProductTableDiv td:last-of-type {
    
}

.ProductTableDiv tr:nth-of-type(even) td {
    background-color: #f3f3f3;
}

.CommandBlockDiv {
    display: flex;;
}

.CommandBlock {
    margin-right: 20px;
    justify-content: space-between;
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    font-size: 18px;
    display: flex;
    align-items: center;
    color: #000000;
}


.CommandBlock i{
    margin-right: 5px;
    font-size: 22px;
    transform: translateY(5px);
}

@media screen and (max-width: 768px) {

    .ProductTableDiv th {
        padding-left: 5px;
        font-size: 15px;
    }

    .CommandBlock {
        margin-right: 5px;
        font-size: 14px;
    }
    
    .CommandBlock i{
        margin-right: 5px;
        font-size: 14px;
        transform: translateY(3px);
    }
}