.TopSection {
    display: flex;
    position: relative;
    padding: 20px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #989898;
}

.TopSection p {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}

.SearchBar {
    position: absolute;
    top: 15px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    height: 32px;
    background-color: #fff;
    border-radius: 8px;
    border: 2px solid #575757;
    padding: 0 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

}

.SearchBar input[type="text"] {
    border: none;
    width: 100%;
    height: 26px;
}

.SearchBar input[type="text"]:focus {
    outline: none;
}

.SearchBar i {
    transform: translateY(2px);
}

.FourInOneSection {
    display: flex;
    padding: 20px;
    justify-content: space-around;
    margin-top: 20px;
    margin-bottom: 8px;
}

.TotalUserDiv {
    position: relative;
    width: 20%;
    height: 100px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.CardText {
    font-size: 18px;
    font-weight: 500;
    margin-top: 30px;
}

.CardIcon {
    position: absolute;
    top: -26px;
    left: 25px;
    width: 60px;
    height: 60px;
    background-color: #f5f5f5;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.CardIcon i {
    transform: translateY(3px);
    font-size: 25px;
    color: #000000;
}

.MainSection {
    position: relative;
    background-color: #ffffff;
    width: 95%;
    min-height: 65vh;
    margin: auto;
    padding: 10px;
    border-radius: 8px;
    border: 1px #3a3a3a solid;
}

.AddNewOrderDiv {
    position: absolute;
    display: flex;
    background-color: #FF7700;
    padding: 6px 12px;
    right: 30px;
    top: -20px;
    border-radius: 8px;
    cursor: pointer;
    align-content: center;
    justify-content: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.AddNewOrderDiv p {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    transform: translateY(1.3px);
    color: #ffffff;
}

.AddNewOrderDiv i{
    font-size:  23px;
    margin-right: 8px;
    transform: translateY(1.6px);
    color: #ffffff;
}

.AddNewOrderDiv:hover {
    position: absolute;
    display: flex;
    background-color: #c85d00;
    padding: 6px 12px;
    right: 30px;
    top: -20px;
    border-radius: 8px;
    cursor: pointer;
    align-content: center;
    justify-content: center;
}

/* Compare this snippet from src/css/C0211/DashboardV2.module.css: */
.UserTableDiv {
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.AddUserDiv {
    display: flex;
    position: absolute;
    right: 30px;
    top: -60px;
    background-color: rgb(55, 55, 55);
    width: 45px;
    height: 45px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.AddUserDiv:hover {
    background-color: #4e4e4e;
}

.AddUserDiv i {
    font-size: 20px;
    color: #ffffff;
    transform: translate(2px, 2px);
}

.UserTableDiv table {
    margin-top: 30px;
    font-family: 'Oswald', sans-serif;
    border-collapse: collapse;
}

.UserTableDiv th {
    background-color: #FFB300;
    color: #282828;
    width: 25vw;
    height: 35px;
}

.UserTableDiv td {
    background-color: #ffffff;
    width: 25vw;
    height: 50px;
    text-align: center;
}

.UserTableDiv tr {
    border-bottom: 1px solid #dddddd;
}

.UserTableDiv tr:last-of-type {
    border-bottom: 2px solid #3a3a3a;
}

.UserTableDiv tr:nth-of-type(even) td {
    background-color: #f3f3f3;
}

.TestScanDiv {
    height: 80px;
    width: 180px;
}

@media screen and (max-width: 768px) {
    .TotalUserDiv {
        padding: 10px;
        width: 20%;
    }

    .CardIcon {
        position: absolute;
        top: -26px;
        left: 15px;
        width: 50px;
        height: 50px;
    }
    
    .CardText {
        font-size: 16px;
        font-weight: 500;
        margin-top: 30px;
    }
}