.MainSection {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9998;
    display: flex;
    justify-content: center;
    align-items: center;  
    align-items: center;
}

.BackgroundDiv {
    border-radius: 24px;
    background-color: #ffffff;
    width: 85%;
    max-height: 90vh;
    overflow: auto;

}

.HeaderDiv {
    position: relative;
    padding: 20px;
}

.Pagetitle {
    font-size: 24px;
    font-weight: 600;
    color: #000;
    margin-left: 10px;
}

.ExitPageDiv {
    position: absolute;
    top: 20px;
    right: 30px;
    cursor: pointer;
}

.ExitPageDiv i{
    font-size: 32px;
    color: #000000;
}

.ProductDetailDiv {
    width: 97%;
    min-height: 10vh;
    margin: auto;
    margin-bottom: 20px;
    display: flex;
    background-color: #ebebeb;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
}

.ProductDisplayFieldDiv {
    padding: 20px;
}

.ProductStockWarehouseDiv {
    padding: 20px;
}

.ProductDetailDiv {
    display: flex;
    flex-wrap: wrap;

}

.EachGroupInputDiv {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.EachProductDetailDiv {
    width: 360px;
    padding: 10px;
    margin-right: 10px;
}

.EachProductDetailIDiv{
    position: relative;
    width: 360px;
    padding: 10px;
    margin-right: 10px;
}

.ProductDetailDiv label {
    margin-left: 2px;
    margin-bottom: 2px;
    font-weight: 500;
    width: 120px;
}

.ProductDetailDiv input[type="text"] {
    margin: 0;
    max-width: 100%;
    /* Set the same width as the textboxes */
    border: 1px solid #ccc;
    /* Border similar to textboxes */
    border-radius: 4px;
    /* Rounded corners */
    font-size: 16px;
    /* Same font size */
    background-color: #fff;
    /* White background for consistency */
    color: #000000;
}

.EditInputTd {
    position: relative;
}


.ProductStockWarehouseDiv table {
    margin-top: 30px;
    font-family: 'Oswald', sans-serif;
    border-collapse: collapse;
}

.ProductStockWarehouseDiv th {
    color: #000000;
    width: 25vw;
    height: 35px;
    text-align: left;

    padding-left: 10px;
}

.ProductStockWarehouseDiv td {
    background-color: #ffffff;
    width: 25vw;
    height: 50px;
    text-align: left;
    padding-left: 10px;
}

.ProductStockWarehouseDiv tr {
    border-bottom: 1px solid #dddddd;
}

.ProductStockWarehouseDiv td:last-of-type {
    
}

.ProductStockWarehouseDiv tr:nth-of-type(even) td {
    background-color: #f3f3f3;
}

.EditInputTd i {
    position: absolute;
    right: 10px;
    top: 18px;
}

.InputFieldbox {
    position: relative;
}

.EachProductDetailIDiv i{
    position: absolute;
    top: 44px;
    right: 20px;

}

@media screen and (max-width: 768px) {

    .Pagetitle {
        margin-left: 10px;
    }
}
