.MainSection {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9998;
    display: flex;
    justify-content: center;
    align-items: center;  
    align-items: center;
}

.BackgroundDiv {
    border-radius: 24px;
    background-color: #ffffff;
    width: 90vw;
}

.HeaderDiv {
    display: flex;
    position: relative;
}

.HeaderDiv p {
    position: absolute;
    font-size: 24px;
    font-weight: bold;
    top: 24px;
    left: 30px;
}

.HeaderDiv i {
    position: absolute;
    font-size: 36px;
    font-weight: bold;
    top: 20px;
    right: 20px;
}

.MainUi {
    display: flex;
    width: 92%;
    margin: auto;
    margin-top: 80px;
    margin-bottom: 20px;
    background-color: #e1e1e1;
    border-radius: 12px;
    padding: 20px;
}

.BasicInputDiv {
    width: 40%;

}

.EachInputDiv {
    margin-bottom: 8px;
}

.EachInputDiv label {
    margin: 0;
    margin-left: 2px;
    font-weight: 500;
}

.ProductTableDiv {
    margin-left: 15px;
    width: 85%;
    background-color: #ffffff;
}

.ProductTableDiv table {
    font-family: 'Oswald', sans-serif;
    border-collapse: collapse;
}

.ProductTableDiv th {
    color: #000000;
    width: 25vw;
    height: 35px;
    text-align: left;
    padding-left: 5px;
}

.ProductTableDiv td {
    background-color: #ffffff;
    width: 25vw;
    height: 50px;
    text-align: left;
    padding-left: 10px;
}

.ProductTableDiv tr {
    border-bottom: 1px solid #dddddd;
}

.ProductTableDiv td:last-of-type {
    
}

.ProductTableDiv tr:nth-of-type(even) td {
    background-color: #f3f3f3;
}
