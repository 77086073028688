.MainSection {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9998;
    display: flex;
    justify-content: center;
    align-items: center;  
    align-items: center;
}

.BackgroundDiv {
    border-radius: 24px;
    background-color: #ffd138;
    width: 85vw;
    height: 90vh;
}

.StockRecordHeaderDiv{
    position: relative;
}

.StockTitleOption {
    display: flex;
    align-items: center;
    padding: 15px;
}

.StockEachShowOption{
    margin-left: 8px;
    font-size: 22px;
    padding: 5px 8px;
    border-radius: 8px;
    color: #FFFFFF;
}

.PageTitle {
    font-size: 24px;
    font-weight: bold;
    margin-left: 5px;
}

.ExitPageDiv {
    position: absolute;
    top: 20px;
    right: 30px;
    cursor: pointer;
}

.ExitPageDiv i{
    font-size: 32px;
    color: #000000;
}

.DisplayProductName {
    font-size: 18px;
    font-weight: bold;
    padding-left: 20px;
}

.ProductDescription {
    color: #2a2a2a;
}

.StockRecordTableDiv table {
    margin-top: 30px;
    font-family: 'Oswald', sans-serif;
    border-collapse: collapse;
}

.StockRecordTableDiv th {
    color: #000000;
    width: 25vw;
    height: 35px;
    text-align: left;

    padding-left: 10px;
}

.StockRecordTableDiv td {
    background-color: #ffffff;
    width: 25vw;
    height: 50px;
    text-align: left;
    padding-left: 10px;
}

.StockRecordTableDiv tr {
    border-bottom: 1px solid #dddddd;
}

.StockRecordTableDiv td:last-of-type {
    
}

.StockRecordTableDiv tr:nth-of-type(even) td {
    background-color: #f3f3f3;
}
