.MainSection {
    width: 95%;
    min-height: 10vh;
    margin: auto;
    padding: 10px;
    padding-top: 30px;
    border-radius: 8px;
    background-color: #ffaa2a;
    overflow: hidden;
    overflow-x: auto;
}

.buttonContainer {
    display: flex;
    align-items: center;
    /* Vertically centers the button, optional */
    margin-left: 20px;
    margin-bottom: 20px;
    /* Space between the button and the next element */
}

.ReturnListBtn {
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #741715;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    display: inline-flex;
    /* Ensure it's a flex container to center text and icon */
    align-items: center;
    /* Vertically centers text and icon */
}

.returntoListIcon {
    font-size: 20px;
    margin-top: 6px;
    margin-right: 10px;
}

.SubTitleDiv {
    display: flex;
    /* Enables flexbox layout */
    align-items: center;
    /* Vertically aligns the h1 and i elements */
    justify-content: space-between;
    /* Optional: Adjusts the spacing between them */
    margin-bottom: 20px;
}

.SubTitleDiv h1 {
    margin: 0;
    /* Optional: Remove default margin from the h1 to better align */
    font-size: 22px;
    font-weight: 600;
    color: #741715;
}

.SubTitleDiv i {
    margin-left: 10px;
    font-size: 22px;
    color: #741715;
    /* Adds some space between the h1 and i element */
}

.uploadInfoItem {
    display: flex;
    align-items: center;
}

.uploadInfoItem label{
    transform: translateY(25%);
    width: 20%;
}

.DisplayInformation {
    position: relative;
}

.HideuploadInfoDiv {
    position: absolute;
    width: 320px;
    background-color: #dddddd;
    box-shadow: #000000 0px 0px 10px;
    border-radius: 8px;
    padding: 10px;
    top: 1.4em;
    right: 1.4em;
}

.HideuploadInfoDiv label{
    transform: translateY(25%);
    width: 30%;
}

.HideuploadInfoDiv input{
    width: 220px;
}

.uploadInfoDiv {
    display: flex;
    flex-direction: column; /* Ensures the items are arranged in a column */
    background-color: #dddddd;
    border-radius: 8px;
    padding: 10px;
}

.baseInfoDiv {
    display: grid;
    grid-template-columns: 320px 460px 460px;
    grid-template-rows: 275px;
    /* height: 280px; */
    width: 85%;
    /* flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; */
}

.detailInfoDiv {
    display: grid;
    grid-template-columns: 320px 400px 400px 460px;
    grid-template-rows: 275px;
    /* height: 280px; */
    width: 98%;
    /* flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; */
}

.baseProductInfoDiv {
    display: grid;
    grid-template-columns: 380px 460px 460px;
    grid-template-rows: 330px;
    /* height: 280px; */
    width: 85%;
    min-height: 380px;
    /* flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; */
}

.userImageDiv {
    position: relative;
    grid-column: 1/1;
    grid-row: 1/1;
    width: 275px;
    height: 275px;
    padding: 15px;
    background-color: #f5f5f5;
    box-shadow: #000000 0px 0px 10px;
    border-radius: 8px;
    margin-right: 20px;
}

.userImageDiv img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}


.ProductImageDiv {
    position: relative;
    grid-column: 1/1;
    grid-row: 1/1;
    width: 330px;
    height: 330px;
    padding: 15px;
    background-color: #f5f5f5;
    box-shadow: #000000 0px 0px 10px;
    border-radius: 8px;
    margin-right: 20px;
}

.ProductImageDiv img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.baseInfo1 {
    grid-column: 2/2;
    grid-row: 1/1;
    width: 90%;
    height: 275px;
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: #000000 0px 0px 10px;
}

.baseProductInfo1 {
    grid-column: 2/2;
    grid-row: 1/1;
    width: 90%;
    height: 330px;
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: #000000 0px 0px 10px;
}

.baseInfo2 {
    grid-column: 3/3;
    grid-row: 1/1;
    width: 90%;
    height: 275px;
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: #000000 0px 0px 10px;
}

.baseInfo3 {
    grid-column: 4/4;
    grid-row: 1/1;
    width: 90%;
    height: 275px;
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: #000000 0px 0px 10px;
}

.PhotoBtn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center; /* or adjust as needed */
    background-color: #741715;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    box-shadow: #000000 0px 0px 3px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    /* Ensure it's a flex container to center text and icon */
    right: 0em;
    top: 12.9em;
    /* Vertically centers text and icon */
}

.PhotoBtn input[type="file"] {
    position: absolute;
    height: 100%;
    width: 100%;
    filter: alpha(opacity=0);
    cursor: pointer;
    opacity: 0;
}

.EditPhotoPanel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center; /* or adjust as needed */
    background-color: #741715;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    box-shadow: #000000 0px 0px 3px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    /* Ensure it's a flex container to center text and icon */
    right: 0em;
    top: 15.8em;
    /* Vertically centers text and icon */
}

.SKUEditPhotoPanel{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center; /* or adjust as needed */
    background-color: #741715;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    box-shadow: #000000 0px 0px 3px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    /* Ensure it's a flex container to center text and icon */
    right: 0em;
    top: 12.9em;
    /* Vertically centers text and icon */
}

.SKUPhotoOptionPanel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center; /* or adjust as needed */
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    border: none;

    border-radius: 5px;
    box-shadow: #000000 0px 0px 3px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    /* Ensure it's a flex container to center text and icon */
    right: 0em;
    top: 15.3em;
    /* Vertically centers text and icon */
}

.PhotoOptionPanel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center; /* or adjust as needed */
    background-color: #ffffff;
    color: rgb(0, 0, 0);
    border: none;

    border-radius: 5px;
    box-shadow: #000000 0px 0px 3px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    /* Ensure it's a flex container to center text and icon */
    right: 0em;
    top: 18.1em;
    /* Vertically centers text and icon */
}

.UploadPhotoPanel ul {
    list-style-type: none;
    padding: 2px 5px;
    margin: 0;
    justify-content: start;
}

.UploadPhotoPanel li {
    border-radius: 8px;
    padding: 5px 12px;

    margin-bottom: 2px;
}

.UploadPhotoPanel li:hover {
 background-color: #fda500;
 color: white;
}



.PhotoProductBtn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center; /* or adjust as needed */
    background-color: #741715;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    box-shadow: #000000 0px 0px 3px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    /* Ensure it's a flex container to center text and icon */
    right: 0em;
    top: 15.7em;
    /* Vertically centers text and icon */
}

.RemovePhotoProductBtn {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center; /* or adjust as needed */
    background-color: #741715;
    color: white;
    border: none;
    padding: 8px 8px;
    border-radius: 5px;
    box-shadow: #000000 0px 0px 3px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    /* Ensure it's a flex container to center text and icon */
    right: 0em;
    top: 0em;
    /* Vertically centers text and icon */
}

.RemovePhotoIcon {
    margin-top: 2px;
    margin-left: 2px;
    color: #ffffff;
    font-size: 22px;
}


.PhotoProductBtn input[type="file"] {
    position: absolute;
    height: 100%;
    width: 100%;
    filter: alpha(opacity=0);
    cursor: pointer;
    opacity: 0;
}

.UploadPhotoPanel input[type="file"] {
    position: absolute;
    /* height: 100%;
    width: 100%; */
    filter: alpha(opacity=0);
    cursor: pointer;
    opacity: 0;
}

.EditPhotoIcon {
    margin-top: 6px;
    margin-right: 10px;
    color: #ffffff;
}

.baseInfoItem {
    margin-bottom: 5px;
}

.baseInfoItem input[type="date"] {
    margin: 0;
    width: 98%;
    /* Set the same width as the textboxes */
    border: 1px solid #ccc;
    /* Border similar to textboxes */
    border-radius: 4px;
    /* Rounded corners */
    font-size: 16px;
    /* Same font size */
    background-color: #fff;
    /* White background for consistency */
    color: #000000;
}

.baseInfoItem input[type="text"] {
    margin: 0;
    width: 100%;
    /* Set the same width as the textboxes */
    border: 1px solid #ccc;
    /* Border similar to textboxes */
    border-radius: 4px;
    /* Rounded corners */
    font-size: 16px;
    /* Same font size */
    background-color: #fff;
    /* White background for consistency */
    color: #000000;
}

.errorbaseInfoItem input[type="text"] {
    margin: 0;
    width: 100%;
    /* Set the same width as the textboxes */
    border: 1px solid #ef2626;
    /* Border similar to textboxes */
    border-radius: 4px;
    /* Rounded corners */
    font-size: 16px;
    /* Same font size */
    background-color: #fff;
    /* White background for consistency */
    color: #000000;
}

.baseInfoItem input[type="number"] {
    margin: 0;
    padding: 5px;
    height: 25px;
    width: 97%;
    /* Set the same width as the textboxes */
    border: 1px solid #ccc;
    /* Border similar to textboxes */
    border-radius: 4px;
    /* Rounded corners */
    font-size: 16px;
    /* Same font size */
    background-color: #fff;
    /* White background for consistency */
    color: #000000;
}

.errorbaseInfoItem input[type="number"] {
    margin: 0;
    padding: 5px;
    height: 25px;
    width: 97%;
    /* Set the same width as the textboxes */
    border: 1px solid #ef2626;
    /* Border similar to textboxes */
    border-radius: 4px;
    /* Rounded corners */
    font-size: 16px;
    /* Same font size */
    background-color: #fff;
    /* White background for consistency */
    color: #000000;
}

.baseInfoItem label {
    margin-left: 2px;
    margin-bottom: 2px;
    font-weight: 500;
}

.baseInfoItem select {
    padding: 3px;
    font-size: 18px;
    width: 100%;
    border-radius: 3px;
    margin-bottom: 5px;
}

.SelectTypeInfoDiv {
    margin-top: 20px;
    display: flex;
}

.SelectTypeBtnContainer {
    display: flex;
    align-items: center;
    /* Vertically centers the button, optional */
    margin-bottom: 20px;
    /* Space between the button and the next element */
    margin-right: 20px;
    margin-top: 10px;
}

.SelectTypeBtn {
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #ef4b25;
    color: white;
    border: none;
    padding: 6px 14px;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    display: inline-flex;
    /* Ensure it's a flex container to center text and icon */
    align-items: center;
    /* Vertically centers text and icon */
}

.SelectTypeIcon {
    font-size: 20px;
    margin-top: 6px;
    margin-right: 10px;
}

.SubSection {
    width: 95%;
    min-height: 40vh;
    margin: auto;
    background: #ffaa2a;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: px;
}

.SubInfoTitleDiv {
    margin-left: 10px;
    font-size: 14px;
    color: #000000;
    margin-bottom: 15px;
}

.personalInfoDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
}

.personalInfoItem {
    width: 30%;
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 8px;
    margin-bottom: 20px;
    margin-right: 20px;
    box-shadow: #000000 0px 0px 10px;
}


.WeightDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
}

.WeightDiv input[type="number"] {
    width: 80%;
}

.WeightDiv select{
    margin-left: 3px;
    width: 15%;
    height: 36px;
}

.ProductTypeFieldset{
    display: flex;
    width: 100%;
    padding: 5px;
    color: #000000;
    font-weight: 600;
}

.Typelable {
    font-size: 18px;
    margin-left: 5px;
}

.TypeFieldset {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    padding: 10px;
    border-radius: 10px;
}

.TypeFieldset legend{
    margin-left: 20px;
    font-size: 18px;
    font-weight: 600;
}

.BottomSection {
    width: 96%;
    margin: auto;
    margin-top: 10px;
    background: #ffaa2a;
    border-radius: 8px;
    padding: 10px;
    justify-content: end;
    align-items: center;
}

.SaveBtnContainer {
    display: flex;
    align-items: center;
    justify-content: end;
}

.BottomBtn {
    background-color: #741715;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    display: inline-flex;
    /* Ensure it's a flex container to center text and icon */
    align-items: center;
    margin-right: 8px;
    /* Vertically centers text and icon */
}

.BottomBtn i{
    margin-top: 10px;
    margin-right: 10px;
}

@media screen and (max-width: 768px) {

    /* .ProductImageDiv {
        width: 200px;
        height: 200px;
    }
    
    .ProductImageDiv img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    } */
    
}