.MainSection {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9998;
    display: flex;
    justify-content: center;
    align-items: center;  
    align-items: center;
}

.BackgroundDiv {
    border-radius: 24px;
    background-color: #ffffff;
    width: 70vw;
}

.HeaderDiv {
    display: flex;
    position: relative;
}

.HeaderDiv p {
    position: absolute;
    font-size: 24px;
    font-weight: bold;
    top: 24px;
    left: 30px;
}

.HeaderDiv i {
    position: absolute;
    font-size: 36px;
    font-weight: bold;
    top: 20px;
    right: 20px;
}

.ProductFunctionListDiv {
    margin-top: 100px;
}

.EachFunctionDiv {
    position: relative;
    display: flex;
    width: 90%;
    margin: auto;
    margin-bottom: 20px;
    border: 2px solid #000000;
    border-radius: 12px;
    padding: 24px 12px;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.3);

    cursor: pointer;
}

.EachFunctionDiv p{
    font-size: 26px;
    font-weight: bold;
}

.EachFunctionDiv i {
    position: absolute;
    font-size: 36px;
    font-weight: bold;
    top: 25px;
    right: 20px;
}

.QuickChangeStockDiv {
    display: flex;
    flex-wrap: wrap;
    width: 95%;
    margin: auto;
    margin-bottom: 20px;
    border: 2px solid #000000;
    border-radius: 12px;
    padding: 24px 12px;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.3);
}

.RequriedFieldDiv {
    background-color: #ffffff;
    border-radius: 8px;
    width: 98%;
    padding: 20px;
    font-weight: 500;
}

.EachGroupInputDiv {
    display: flex;
    margin-bottom: 10px;
}

.DatePicker {
    background-color: rgb(255, 255, 255);
    border: 1px solid #000000;
    width: 180px;
    height: 42px;
    margin-right: 20px;
}

.EachProductDetailDiv {
    margin-right: 20px;
}

.EachProductDetailDiv input[type="number"] {
    margin: 0;
    width: 120px;
    height: 38px;
    /* Set the same width as the textboxes */
    background-color: rgb(236, 236, 236);
    /* Border similar to textboxes */
    border-radius: 4px;
    /* Rounded corners */
    font-size: 16px;
    /* Same font size */
    background-color: #fff;
    /* White background for consistency */
    color: #000000;
    padding-left: 5px;
}

.ButtonDiv {
    width: 100%;
    display: flex;
    justify-content: end;
    margin-top: 10px;
}

.ButtonDiv button {
    width: 100px;
    height: 40px;
    background-color: #fcb900;
    color: #000000;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.3);
}

.EachProductDetailDiv select {
    padding: 3px;
    font-size: 18px;
    width: 100%;
    height: 41px;
    border-radius: 5px;
    margin-bottom: 5px;
    border: 2px solid #000000;
}

.remarkinput {
    resize: none;
}

@media screen and (max-width: 768px) {

    .QuickChangeStockDiv {
        width: 90%;
    }
}