.Layout {
    display: grid;
    grid-template-columns: 240px 1fr;
    grid-row: 1fr;
}

.HideLayout {
    display: grid;
    grid-template-columns: 60px 1fr;
    grid-row: 1fr;
}

.Navbar {
    grid-column: 1 / 1;
    grid-row: 1 / 1;
    background-color: #f5f5f5;
    border-right: 1px solid #e0e0e0;
    padding: 20px;
}

.Outlet {
    grid-column: 2 / 2;
    grid-row: 1 / 1;
    max-height: 100vh;
    min-height: 100vh;
    overflow-y: auto;
    background-color: #f1f1f1;
}