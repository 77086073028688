.MainSection {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;  
    align-items: center;
}

.ControlDiv {
    position: absolute;
    display: flex;
    top: 30px;
    right: 30px;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-radius: 5px;
}

.ControlDiv i {
    font-size: 18px;
    transform: translateY(1px);
}

.ScanBarCodeDiv {
    background-color: #ffffff;
    width: 680px;
}